import React from 'react'
import styled from 'styled-components'

const IndustryRightCol = ({ children }) => {
  return <Container>{children}</Container>
}

export default IndustryRightCol

const Container = styled.div`
  width: 100%;
  padding-left: 0;
  @media (min-width: 1024px) {
    padding-left: 11%;
  }
`
