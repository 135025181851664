import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import theme from '../styling/theme'
import arrhead from '../../images/back-left-arrow.svg'
import '../shared/Animations/Animations.css'

interface ICat {
  category: string
  title: string
  backLink: string
}
const IndustryLeftCol = ({ category, title, backLink }: ICat) => {
  return (
    <Container>
      <Inner>
        <Title>
          <Category>{category}</Category>
          <Name className="p-large">{title}</Name>
        </Title>
        <BackLink
          to={(process.env.BASE === '/' ? '' : process.env.BASE) + backLink}
        >
          <Arrow src={arrhead} alt="Arrow back" />
          <Line />
        </BackLink>
      </Inner>
    </Container>
  )
}

export default IndustryLeftCol

const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 264px;
  position: relative;
  padding-bottom: 37px;
  @media (min-width: 1024px) {
    padding-bottom: 10rem;
  }
`
const Inner = styled.div`
  @media (min-width: 1024px) {
    position: sticky;
    left: 0;
    bottom: 0;
    top: 100px;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

const Category = styled.h6`
  font-weight: bold;
`

const Name = styled.p`
  display: block;
`
const BackLink = styled(props => <Link {...props} />)`
  display: flex;
  align-items: center;
  width: 280px;
  margin-bottom: 18px;
`

const Arrow = styled.img`
  height: 40px;
  transition: all 0.5s ease;
  position: relative;
  @media (min-width: 1024px) {
    left: -10px;
    animation: arrowCourseTemplate ease 0.5s forwards 0.5s;
    ${BackLink}:hover & {
      transform: translateX(-20px);
    }
  }
`

const Line = styled.div`
  display: inline;
  position: relative;
  width: 100%;
  height: 2px;
  background: ${theme.brand.blue};
  transition: all 0.5s ease;
  @media (min-width: 1024px) {
    width: 0%;
    animation: growLineCourseTemplate ease 0.5s forwards 0.5s;
    ${BackLink}:hover & {
      transform: translateX(-40px);
    }
  }
`

const Description = styled.p`
  color: ${theme.text.second};
  font-weight: 300;
`
