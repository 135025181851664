import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'
import RequestCallBackModal from '../shared/Modal/RequestCallBackModal'
import MakeClaimModal from '../shared/Modal/MakeClaimModal'

const links = {
  ctaTitle1: 'Make a claim',
  ctaTitle2: 'Request a call back',
}

interface IIndustry {
  title?: string
}
const IndustryDescription = ({ title }: IIndustry) => {
  const [showCallBackModal, toggleCallBackModal] = useState(false)
  const [showClaimModal, toggleClaimModal] = useState(false)
  return (
    <Container>
      <MakeClaimModal
        showModal={showClaimModal}
        toggleModal={() => toggleClaimModal(false)}
        type={'Get in touch'}
        body={'Inactive account'}
      />
      <RequestCallBackModal
        showModal={showCallBackModal}
        toggleModal={() => toggleCallBackModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <Title>{title}</Title>

      {links.ctaTitle1 !== '' && (
        <ButtonContainer>
          <Button onClick={() => toggleClaimModal(true)}>
            {links.ctaTitle1}
          </Button>

          <Button onClick={() => toggleCallBackModal(true)} secondary>
            {links.ctaTitle2}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}

export default IndustryDescription

const Container = styled.section`
  border-bottom: 3px solid ${theme.grey.keyline};
`

const Title = styled.h3`
  margin-bottom: 30px;

  @media (min-width: 1024px) {
    margin-bottom: 37px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin-top: 33px;
  margin-bottom: 23px;
  & a:first-of-type {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 45px;
    & a:first-of-type {
      margin-bottom: 0px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 490px;
    margin: 30px 0;
  }
`
