import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'

const IndustryTemplateLayout = ({ children }) => {
  return (
    <MainGrid>
      <Container>{children}</Container>
    </MainGrid>
  )
}

export default IndustryTemplateLayout

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 65px 0 0;
  }
`
