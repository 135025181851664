import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

interface IContent {
  heading?: string
  paragraph?: string
  imgDesktop?: string
  imgMobile?: string
  alt?: string
  content?: any
}
const IndustryContent = ({
  heading,
  paragraph,
  imgDesktop,
  imgMobile,
  alt,
  content,
}: IContent) => {
  return (
    <Container>
      {heading && <h6>{heading}</h6>}
      {paragraph && <p>{paragraph}</p>}
      {imgDesktop && (
        <>
          <Image src={imgDesktop} alt={alt} />{' '}
          <ImgMobile src={imgMobile} alt={alt} />
        </>
      )}
      {content && content}
    </Container>
  )
}

export default IndustryContent

const Container = styled.div`
  padding: 50px 0;

  @media (min-width: 1024px) {
    padding: 8rem 0 8rem;
  }

  & h6 {
    font-weight: bold;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-bottom: 28px;
    }
  }

  & p {
    margin-bottom: 20px;
  }

  & li {
    color: ${theme.text.greyLight};
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;

    @media (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

    &:before {
      height: 15px;
      width: 15px;
      min-height: 15px;
      min-width: 15px;
    }
  }

  & ul {
    margin-bottom: 20px;
  }
`
const Image = styled.img`
  display: none;
  @media (min-width: 768px) {
    max-width: 50vw;
    display: block;
    margin: 40px 0 48px;
  }
  @media (min-width: 1024px) {
    max-width: 100%;
  }
`

const ImgMobile = styled.img`
  margin-bottom: 20px;
  max-width: 450px;
  @media (min-width: 768px) {
    display: none;
  }
`
